import { CURRENCY_OPTIONS } from './index'

export const PLATFORM_LIST = [
  // {
  //   id: 'AELIVECOMM',
  //   name: 'AELIVECOMM',
  //   abbr: 'AE7',
  //   actions: ['bet', 'cancelBet', 'settle', 'resettle', 'voidSettle', 'tip', 'cancelTip'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'userId2', 'userId3', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 50,
  // },
  // {
  //   id: 'AELOTTO',
  //   name: 'AE LOTTO',
  //   abbr: 'AEL',
  //   actions: ['bet', 'cancelBet', 'settle', 'unsettle', 'voidBet'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 10,
  // },
  // {
  //   id: 'AWSEGAME',
  //   name: 'AWS EGAME',
  //   abbr: 'AEE',
  //   actions: ['betNSettle', 'cancelBettleNSettle'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 10,
  // },
  // {
  //   id: 'AWSSLOT',
  //   name: 'AWS SLOT',
  //   abbr: 'AES',
  //   actions: ['betNSettle', 'cancelBettleNSettle'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 10,
  // },
  // {
  //   id: 'AWSTABLE',
  //   name: 'AWS TABLE',
  //   abbr: 'AET',
  //   actions: ['betNSettle', 'cancelBettleNSettle'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 10,
  // },
  {
    id: 'BG',
    name: 'BG',
    abbr: 'BG',
    actions: ['bet', 'cancelBet', 'settle', 'resettle', 'voidBet', 'voidSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 50,
  },
  {
    id: 'BTG',
    name: 'BTG',
    abbr: 'BTG',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'CGEGAME',
    name: 'CG EGAME',
    abbr: 'CGE',
    actions: ['bet', 'cancelBet', 'settle', 'voidSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'CGSLOT',
    name: 'CG SLOT',
    abbr: 'CGS',
    actions: ['bet', 'cancelBet', 'settle', 'voidSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'DRAGOONSOFT',
    name: 'DRAGOONSOFT',
    abbr: 'DGS',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'E1SPORT',
    name: 'E1SPORT',
    abbr: 'ESP',
    actions: ['bet', 'cancelBet', 'adjustBet', 'settle', 'resettle', 'voidBet', 'voidSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 25,
  },
  {
    id: 'EVOLUTION',
    name: 'EVOLUTION',
    abbr: 'EVO',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'FASTSPINSLOT',
    name: 'FASTSPIN SLOT',
    abbr: 'FSS',
    actions: ['bet', 'cancelBet', 'settle', 'give'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'FASTSPINFISH',
    name: 'FASTSPIN FISH',
    abbr: 'FSF',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'FCEGAME',
    name: 'FC EGAME',
    abbr: 'FCE',
    actions: ['bet', 'cancelBet', 'settle', 'betNSettle', 'cancelBettleNSettle', 'give'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'FCFISH',
    name: 'FC FISH',
    abbr: 'FCF',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'FCSLOT',
    name: 'FC SLOT',
    abbr: 'FCS',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'FIVEG',
    name: 'FIVEG',
    abbr: '5GS',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'GTFFISH',
    name: 'GTF FISH',
    abbr: 'GTFF',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'GTFSLOT',
    name: 'GTF SLOT',
    abbr: 'GTFS',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'HORSEBOOK',
    name: 'HORSEBOOK',
    abbr: 'HRB',
    actions: ['bet', 'cancelBet', 'adjustBet', 'settle', 'unsettle', 'voidBet', 'unvoidBet', 'voidSettle'],
    formFields: ['callbackUrl', 'key', 'userIdPrefix', 'userId1', 'userId2', 'userId3', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 45,
  },
  {
    id: 'HOTROAD',
    name: 'HOTROAD',
    abbr: 'HR',
    actions: ['bet', 'cancelBet', 'settle', 'voidBet'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'ILOVEU',
    name: 'ILOVEU',
    abbr: 'ILU',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  // {
  //   id: 'INSPORTS',
  //   name: 'INSPORTS',
  //   abbr: 'ISP',
  //   actions: ['bet', 'cancelBet', 'adjustBet', 'voidtBet', 'settle', 'resettle'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 10,
  // },
  {
    id: 'JDBEGAME',
    name: 'JDB EGAME',
    abbr: 'JDBE',
    actions: ['bet', 'cancelBet', 'settle', 'betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'JDBFISH',
    name: 'JDB FISH',
    abbr: 'JDBF',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'JDBSLOT',
    name: 'JDB SLOT',
    abbr: 'JDBS',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'JDBTABLE',
    name: 'JDB TABLE',
    abbr: 'JDBT',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'JILIFISH',
    name: 'JILI FISH',
    abbr: 'JLF',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'JILISLOT',
    name: 'JILI SLOT',
    abbr: 'JLS',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'JILITABLE',
    name: 'JILI TABLE',
    abbr: 'JLT',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'JOKER',
    name: 'JOKER',
    abbr: 'JK',
    actions: ['bet', 'cancelBet', 'settle', 'betNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'KINGMAKERSLOT',
    name: 'KINGMAKER (KINGMIDAS) SLOT',
    abbr: 'KMS',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userIdPrefix', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'KINGMAKERTABLE',
    name: 'KINGMAKER (KINGMIDAS) TABLE',
    abbr: 'KMT',
    actions: ['bet', 'cancelBet', 'settle', 'give'],
    formFields: ['callbackUrl', 'key', 'userIdPrefix', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'KINGMAKERMINI',
    name: 'KINGMAKERMINI',
    abbr: 'KMMN',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userIdPrefix', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'LADYLUCK',
    name: 'LADYLUCK',
    abbr: 'LL',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'LUCKYPOKER',
    name: 'LUCKYPOKER',
    abbr: 'LP',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userIdPrefix', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'LUDO',
    name: 'LUDO',
    abbr: 'LD',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userIdPrefix', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'NETENTSLOT',
    name: 'NETENT SLOT',
    abbr: 'NES',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'NETENTTABLE',
    name: 'NETENT TABLE',
    abbr: 'NET',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'NLC',
    name: 'NLC',
    abbr: 'NLC',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'PGSLOT',
    name: 'PG SLOT',
    abbr: 'PGS',
    actions: ['betNSettle', 'cancelBettleNSettle', 'give'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  // {
  //   id: 'PGSLOTOLD',
  //   name: 'PG SLOT (bet/settle)',
  //   abbr: 'PGSO',
  //   actions: ['bet', 'cancelBet', 'settle'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 3,
  // },
  {
    id: 'PGTABLE',
    name: 'PG TABLE',
    abbr: 'PGT',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'PINNACLE',
    name: 'PINNACLE',
    abbr: 'PNC',
    actions: ['bet', 'cancelBet', 'adjustBet', 'settle', 'resettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'PLAY8FISH',
    name: 'PLAY8 FISH',
    abbr: 'P8F',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'PLAY8SLOT',
    name: 'PLAY8 SLOT',
    abbr: 'P8S',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'PPEGAME',
    name: 'PP EGAME',
    abbr: 'PPE',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'PPLIVE',
    name: 'PP LIVE',
    abbr: 'PPL',
    actions: ['bet', 'cancelBet', 'settle', 'resettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'PPSLOT',
    name: 'PP SLOT',
    abbr: 'PPS',
    actions: ['bet', 'cancelBet', 'settle', 'give'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'PTLIVE',
    name: 'PT LIVE',
    abbr: 'PTL',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'PTSLOT',
    name: 'PT SLOT',
    abbr: 'PTS',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'RTSLOT',
    name: 'RT SLOT',
    abbr: 'RTS',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'RTTABLE',
    name: 'RT TABLE',
    abbr: 'RTT',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'SABA',
    name: 'SABA',
    abbr: 'SB',
    actions: ['bet', 'cancelBet', 'adjustBet', 'settle', 'resettle', 'unsettle', 'voidBet', 'voidSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  // {
  //   id: 'SABAOLD',
  //   name: 'SABA',
  //   abbr: 'SBO',
  //   actions: ['bet', 'cancelBet', 'settle', 'unsettle', 'voidBet', 'voidSettle'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 10,
  // },
  {
    id: 'SEXYBCRT',
    name: 'SEXYBCRT',
    abbr: 'SXB',
    actions: ['bet', 'cancelBet', 'settle', 'resettle', 'voidBet', 'voidSettle', 'tip', 'cancelTip'],
    formFields: ['callbackUrl', 'key', 'userId1', 'userId2', 'userId3', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 50,
  },
  {
    id: 'SEXYBCRT_SUMMARY',
    name: 'SEXYBCRT (SUMMARY)',
    abbr: 'SXS',
    actions: ['bet', 'cancelBet', 'settle', 'voidBet', 'tip', 'cancelTip'],
    formFields: ['callbackUrl', 'key', 'userIdPrefix', 'userId1', 'userId2', 'userId3', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 50,
  },
  // {
  //   id: 'SEXYBCRT_ONEDAY',
  //   name: 'SEXYBCRT - OneDay',
  //   abbr: 'OD',
  //   actions: ['adjustAmt', 'cancelAdjustAmt'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 3,
  // },
  {
    id: 'SKYCASINO',
    name: 'SKYCASINO',
    abbr: 'SKC',
    actions: ['bet', 'cancelBet', 'settle', 'resettle', 'voidBet', 'voidSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'userId2', 'userId3', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 50,
  },
  {
    id: 'SPADEEGAME',
    name: 'SPADE EGAME',
    abbr: 'SGE',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'SPADEFISH',
    name: 'SPADE FISH',
    abbr: 'SGF',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'SPADESLOT',
    name: 'SPADE SLOT',
    abbr: 'SGS',
    actions: ['bet', 'cancelBet', 'settle', 'freeSpin', 'give'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'SPRIBE',
    name: 'SPRIBE',
    abbr: 'SPR',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'SV388',
    name: 'SV388',
    abbr: 'CF',
    actions: ['bet', 'cancelBet', 'adjustBet', 'refund', 'settle', 'unsettle', 'voidBet', 'unvoidBet', 'voidSettle', 'unvoidSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'userId2', 'userId3', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 25,
  },
  // {
  //   id: 'VENUS',
  //   name: 'VENUS',
  //   abbr: 'VN',
  //   actions: ['bet', 'cancelBet', 'settle', 'resettle', 'voidBet', 'voidSettle'],
  //   formFields: ['callbackUrl', 'key', 'userId1', 'userId2', 'userId3', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
  //   balanceMultiplier: 50,
  // },
  {
    id: 'VIACASINO',
    name: 'VIACASINO',
    abbr: 'VC',
    actions: ['bet', 'cancelBet', 'settle', 'resettle', 'voidBet', 'voidSettle'],
    formFields: ['callbackUrl', 'key', 'userIdPrefix', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 50,
  },
  {
    id: 'VRLOTTO',
    name: 'VRLOTTO',
    abbr: 'VRL',
    actions: ['bet', 'cancelBet', 'settle', 'resettle', 'voidBet'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'YESBINGOBINGO',
    name: 'YESBINGO (YELLOWBAT) BINGO',
    abbr: 'YBB',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'YESBINGOEGAME',
    name: 'YESBINGO (YELLOWBAT) EGAME',
    abbr: 'YBS',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'YESBINGOFISH',
    name: 'YESBINGO (YELLOWBAT) FISH',
    abbr: 'YBF',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'YESBINGOSLOT',
    name: 'YESBINGO (YELLOWBAT) SLOT',
    abbr: 'YBS',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
  {
    id: 'YLEGAME',
    name: 'YL EGAME',
    abbr: 'YLE',
    actions: ['bet', 'cancelBet', 'settle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'YLFISH',
    name: 'YL FISH',
    abbr: 'YLF',
    actions: ['betNSettle', 'cancelBettleNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 10,
  },
  {
    id: 'PGSLOTMIXED',
    name: 'INTERNAL USE',
    abbr: 'PGSM',
    actions: ['bet', 'settle', 'betNSettle'],
    formFields: ['callbackUrl', 'key', 'userId1', 'currency', 'betAmount', 'balanceDecimalPlaces', 'skippedTestScenarios'],
    balanceMultiplier: 3,
  },
]

export const ACTION_LIST = [
  {
    id: 'bet',
    name: 'Bet',
  },
  {
    id: 'cancelBet',
    name: 'CancelBet',
  },
  {
    id: 'settle',
    name: 'Settle',
  },
  {
    id: 'adjustBet',
    name: 'AdjustBet',
  },
  {
    id: 'unsettle',
    name: 'Unsettle',
  },
  {
    id: 'resettle',
    name: 'Resettle',
  },
  {
    id: 'voidBet',
    name: 'VoidBet',
  },
  {
    id: 'unvoidBet',
    name: 'UnvoidBet',
  },
  {
    id: 'voidSettle',
    name: 'VoidSettle',
  },
  {
    id: 'unvoidSettle',
    name: 'UnvoidSettle',
  },
  {
    id: 'betNSettle',
    name: 'BetNSettle',
  },
  {
    id: 'cancelBettleNSettle',
    name: 'CancelBettleNSettle',
  },
  {
    id: 'adjustAmt',
    name: 'AdjustAmt',
  },
  {
    id: 'cancelAdjustAmt',
    name: 'CancelAdjustAmt',
  },
  {
    id: 'tip',
    name: 'Tip',
  },
  {
    id: 'cancelTip',
    name: 'CancelTip',
  },
  {
    id: 'give',
    name: 'Give',
  },
  {
    id: 'refund',
    name: 'Refund',
  },
  {
    id: 'freeSpin',
    name: 'FreeSpin',
  },
]

export const PARAMETER_FORM_FIELDS = [
  {
    id: 'callbackUrl',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    rules: [
      {
        required: true,
        message: 'rule-correct-url-format',
      },
      {
        // type: 'url',
        // eslint-disable-next-line no-useless-escape
        pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]){0,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/,
        message: 'rule-correct-url-format',
        trigger: 'blur',
      },
    ],
  },
  {
    id: 'key',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    rules: [
      {
        required: true,
        message: 'rule-enter-key',
      },
    ],
  },
  {
    id: 'userIdPrefix',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    maxlength: 3,
    rules: [
      {
        required: true,
        message: 'rule-enter-user-id-prefix',
      },
      {
        pattern: /^[A-Za-z0-9]+$/,
        message: 'rule-correct-user-id-prefix-format',
        trigger: 'blur',
      },
    ],
  },
  {
    id: 'userId1',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    maxlength: 21,
    rules: [
      {
        required: true,
        message: 'rule-enter-user-id-1',
      },
      {
        pattern: /^[A-Za-z0-9]+$/,
        message: 'rule-correct-user-id-format',
        trigger: 'blur',
      },
    ],
    toolTip: 'tip-user-id',
    toolTipTrigger: 'hover',
  },
  {
    id: 'userId2',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    maxlength: 21,
    rules: [
      {
        required: true,
        message: 'rule-enter-user-id-2',
      },
      {
        pattern: /^[A-Za-z0-9]+$/,
        message: 'rule-correct-user-id-format',
        trigger: 'blur',
      },
    ],
    toolTip: 'tip-user-id-same-currency',
    toolTipTrigger: 'hover',
  },
  {
    id: 'userId3',
    defaultValue: undefined,
    element: 'input',
    inputType: 'text',
    maxlength: 21,
    rules: [
      {
        required: true,
        message: 'rule-enter-user-id-3',
      },
      {
        pattern: /^[A-Za-z0-9]+$/,
        message: 'rule-correct-user-id-format',
        trigger: 'blur',
      },
    ],
    toolTip: 'tip-user-id-same-currency',
    toolTipTrigger: 'hover',
  },
  {
    id: 'currency',
    defaultValue: undefined,
    element: 'select',
    options: CURRENCY_OPTIONS,
    rules: [
      {
        required: true,
        message: 'rule-select-currency',
      },
    ],
    toolTipTrigger: 'hover',
    showSearch: true,
  },
  {
    id: 'betAmount',
    defaultValue: undefined,
    element: 'input-number',
    inputType: 'number',
    min: 0.01,
    max: 9999999999999.99,
    step: 0.01,
    rules: [
      {
        required: true,
        message: 'rule-enter-bet-amount',
      },
    ],
    toolTip: 'tip-bet-amount',
    toolTipTrigger: 'focus',
  },
  {
    id: 'balanceDecimalPlaces',
    defaultValue: 3,
    inputType: 'number',
    element: 'select',
    options: [0, 1, 2, 3].map(i => ({ label: i, value: i })),
    rules: [
      {
        required: true,
        message: 'rule-select-balanceDecimalPlaces',
      },
    ],
    toolTip: 'tip-balanceDecimalPlaces',
    toolTipTrigger: 'hover',
  },
  {
    id: 'skippedTestScenarios',
    defaultValue: [],
    rules: [],
  },
]

export const DEFAULT_PLATFORM_CLIENT_DATA = {
  // callbackUrl: 'https://uat-provider.iconig.com/postback/sexygamingae/v1',
  // key: 'dETR6pqPMIMGNOEynBx',
  // userIdPrefix: 'wop',
  // userId1: 'icomstrae06',
  // userId2: 'icomstrae07',
  // userId3: 'icomstrae08',
  // currency: 'THB',
  // betAmount: 10,
  // balanceDecimalPlaces: 3,

  // callbackUrl: 'http://localhost:8081/single-wallet-agent/api/callback/STG',
  // cert: '18YDtxChFc24nf7763C',
  // userIdPrefix: 'ts0',
  // // cert: 'HfnDP8hyRpxuc6bjiIi',
  // // userIdPrefix: 'sw0',

  callbackUrl: 'http://localhost:8081/single-wallet-agent/api/callback/UAT',
  key: 'rGjZikeTOMVR9g11RMY',
  userIdPrefix: 'ts0',
  // key: 'gDvXVKDDcuMirCnMOF9',
  // userIdPrefix: 'sw0',
  userId1: 'thbtest1',
  userId2: 'thbtest2',
  userId3: 'thbtest3',
  currency: 'THB',
  betAmount: 10,
  balanceDecimalPlaces: 3,

  // callbackUrl: 'https://api-test.ambnewsportsbook.com/sbso/v1/sexy-jpy/callback',
  // key: '9nQuLE07LvpVFxLS14N',
  // userIdPrefix: 'pre',
  // userId1: 'SEXTEST000BTW0',
  // userId2: 'SEXTEST000BTW1',
  // userId3: 'SEXTEST000BTW7',
  // currency: 'JPY',
  // betAmount: 1,
  // balanceDecimalPlaces: 3,

  // callbackUrl: 'http://18.178.69.79/api/callback/SEX/gateway',
  // key: 'U791QgaBTHU9kEqqHeQ',
  // userIdPrefix: 'pre',
  // userId1: '99umbyntestam1',
  // userId2: '99umbyntestam2',
  // userId3: '99umbdst333',
  // currency: 'MYR',
  // betAmount: 1,
  // balanceDecimalPlaces: 2,

  // callbackUrl: 'http://www.myawaddyonline999.com/callbackswl/callback.php',
  // key: '>^.K4_3wnkk5&MgQ',
  // userIdPrefix: 'pre',
  // userId1: 'awctestcny',
  // userId2: 'awctestcny2',
  // userId3: 'awctestcny3',
  // currency: 'CNY',
  // betAmount: 10,
  // balanceDecimalPlaces: 2,
  // skippedTestScenarios: [],
}
