/* eslint-disable camelcase */

import { PLATFORM_LIST } from '../../../constants/singleWalletTesting'
import { bet_settle, betNSettle, betNSettle_requireAmt } from './basic'

import AELIVECOMM from './AELIVECOMM'
import AELOTTO from './AELOTTO'
import BG from './BG'
import E1SPORT from './E1SPORT'
import FASTSPINSLOT from './FASTSPINSLOT'
import FCEGAME from './FCEGAME'
import HORSEBOOK from './HORSEBOOK'
import HOTROAD from './HOTROAD'
import JDBEGAME from './JDBEGAME'
import JILITABLE from './JILITABLE'
import JOKER from './JOKER'
import KINGMAKERSLOT from './KINGMAKERSLOT'
import KINGMAKERTABLE from './KINGMAKERTABLE'
import KINGMAKERMINI from './KINGMAKERMINI'
import LUCKYPOKER from './LUCKYPOKER'
import PGSLOT from './PGSLOT'
import PGSLOTMIXED from './PGSLOTMIXED'
import PINNACLE from './PINNACLE'
import PPLIVE from './PPLIVE'
import PPSLOT from './PPSLOT'
import RTSLOT from './RTSLOT'
import RTTABLE from './RTTABLE'
import SABA from './SABA'
import SEXYBCRT from './SEXYBCRT'
import SEXYBCRT_ONEDAY from './SEXYBCRT_ONEDAY'
import SEXYBCRT_SUMMARY from './SEXYBCRT_SUMMARY'
import SKYCASINO from './SKYCASINO'
import SPADESLOT from './SPADESLOT'
import SV388 from './SV388'
import VIACASINO from './VIACASINO'
import VRLOTTO from './VRLOTTO'
import YLFISH from './YLFISH'
import CG from './CG'
import INSPORTS from './INSPORTS'

const platformTestScenarioList = {
  AELIVECOMM,
  AELOTTO,
  AWSEGAME: betNSettle,
  AWSSLOT: betNSettle,
  AWSTABLE: betNSettle,
  BG,
  BTG: bet_settle,
  CGEGAME: CG,
  CGSLOT: CG,
  DRAGOONSOFT: bet_settle,
  E1SPORT,
  EVOLUTION: bet_settle,
  FASTSPINSLOT,
  FASTSPINFISH: betNSettle,
  FCEGAME,
  FCFISH: betNSettle_requireAmt,
  FCSLOT: betNSettle,
  FIVEG: bet_settle,
  GTFFISH: betNSettle_requireAmt,
  GTFSLOT: betNSettle,
  HORSEBOOK,
  HOTROAD,
  ILOVEU: betNSettle,
  INSPORTS,
  JDBEGAME,
  JDBFISH: betNSettle_requireAmt,
  JDBSLOT: betNSettle,
  JDBTABLE: bet_settle,
  JILIFISH: betNSettle,
  JILISLOT: betNSettle,
  JILITABLE,
  JOKER,
  KINGMAKERSLOT,
  KINGMAKERTABLE,
  KINGMAKERMINI,
  LADYLUCK: bet_settle,
  LUCKYPOKER,
  LUDO: bet_settle,
  NETENTSLOT: bet_settle,
  NETENTTABLE: bet_settle,
  NLC: bet_settle,
  PGSLOT,
  PGSLOTMIXED,
  PGTABLE: betNSettle,
  PINNACLE,
  PLAY8FISH: betNSettle,
  PLAY8SLOT: betNSettle,
  PPEGAME: bet_settle,
  PPLIVE,
  PPSLOT,
  PTLIVE: bet_settle,
  PTSLOT: bet_settle,
  RTSLOT,
  RTTABLE,
  SABA,
  SEXYBCRT,
  SEXYBCRT_ONEDAY,
  SEXYBCRT_SUMMARY,
  SKYCASINO,
  SPADEEGAME: bet_settle,
  SPADEFISH: betNSettle_requireAmt,
  SPADESLOT,
  SPRIBE: bet_settle,
  SV388,
  VENUS: SEXYBCRT,
  VIACASINO,
  VRLOTTO,
  YESBINGOBINGO: bet_settle,
  YESBINGOEGAME: bet_settle,
  YESBINGOFISH: betNSettle,
  YESBINGOSLOT: bet_settle,
  YLEGAME: bet_settle,
  YLFISH,
}

export default PLATFORM_LIST.reduce((acc, { id: platform, abbr }) => {
  acc[platform] = platformTestScenarioList[platform].reduce((acc2, scenario, idx) => {
    acc2[`${abbr}-${idx + 1}`] = scenario
    return acc2
  }, {})

  return acc
}, {})
