import PlatformActions from './PlatformActions'

export default class FIVEGActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      platform: 'FIVEG',
      gameType: 'SLOT',
      gameCode: '5G-SLOT-004',
    }

    this.gameName = 'MAHJONG WAYS 3+'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 2471844296
      roundId: txnId, // 2471844296
      betType: null,
      currency,
      betTime, // 2025-03-11T18:11:19.000+08:00
      betAmount,
      gameInfo: {
        subgameId: '0',
      },
      isPremium: false,

    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2025-03-11T18:11:20.336+08:00
      roundId: txnId,
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        subgameId: '0',
        jpContrib: '0.00',
        bonusWin: '0',
        hasBonus: '0',
      },

    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId: txnId,
      gameInfo: {
        subgameId: '0',
      },

    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
