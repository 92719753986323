import PlatformAdjustBetTestScenarios from './PlatformAdjustBetTestScenarios'

// oddsType:
// MY: 1
// CN: 2
// DEC: 3
// IND: 4
// US: 5

/* eslint-disable camelcase, no-await-in-loop */
export default class SABATestScenarios extends PlatformAdjustBetTestScenarios {
  async bet_adjustBet_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '1' } }) // MY [-]
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({
          data: { oddsType: '1' }, // MY [+]
          options: { positiveOdds: true },
        })
        await this.requests.adjustBet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '3' } }) // DEC [+]
        await this.requests.adjustBet()

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.settleWin({ timesLoop })
        }

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleLose_unsettle_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '4' }, // IND [+]
          options: { positiveOdds: true },
        })
        await this.requests.adjustBet()

        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleWin_unsettle_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '4' } }) // IND [-]
        await this.requests.adjustBet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_voidBet5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '5' } }) // US [-]
        await this.requests.adjustBet()

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.voidBet({ timesLoop })
        }
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_adjustBet_settleWin_unsettle_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '5' }, // US [+]
          options: { positiveOdds: true },
        })
        await this.requests.adjustBet()

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })

        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_adjustBet_settleLose_unsettle_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '4' } }) // IND [-]
        await this.requests.adjustBet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle()
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_adjustBet_settleWin_voidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '4' }, // IND [+]
          options: { positiveOdds: true },
        })
        await this.requests.adjustBet()

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_adjustBet_settleWin_voidSettle5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '3' }, // DEC [+]
          options: { positiveOdds: true },
        })
        await this.requests.adjustBet()

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.voidSettle({ timesLoop })
        }
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async betOnParlay_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: {
            oddsType: '3', // DEC [+]
            isParlay: true,
          },
        })
        await this.requests.adjustBet()

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const oddsList = [1.24, -1.17, 1.53]
        const betIndexes = []

        for (let betIndex = 0; betIndex < oddsList.length; betIndex += 1) {
          await this.requests.bet({
            betIndexes: [betIndex],
            data: {
              odds: oddsList[betIndex],
              oddsType: '1', // MY
            },
          })
          await this.requests.adjustBet({ betIndexes: [betIndex] })

          betIndexes.push(betIndex)
        }

        await this.requests.settleWin({ betIndexes })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_unsettle1_settleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsType: '1' }, // MY [+]
          options: { positiveOdds: true },
        })

        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: {
            oddsType: '1', // MY [+]
          },
          options: {
            positiveOdds: true,
            differentRound: true,
          },
        })

        await this.requests.adjustBet({ betIndexes: [1] })

        await this.requests.unsettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleLose({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_unsettle1_voidBet1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsType: '4' }, // IND [+]
          options: { positiveOdds: true },
        })

        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: {
            odds: 1.1,
            oddsType: '4', // IND [+]
          },
          options: {
            positiveOdds: true,
            differentRound: true,
          },
        })

        await this.requests.adjustBet({ betIndexes: [1] })

        await this.requests.unsettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.voidBet({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'voidBet',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBetForOddsChange_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '5' } }) // US [-]
        await this.requests.adjustBet({ options: { forOddsChange: true } })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async betOnPositiveOdds_adjustBetForOddsChange_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({
          data: { oddsType: '5' }, // US [+]
          options: { positiveOdds: true },
        })
        await this.requests.adjustBet({ options: { forOddsChange: true } })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_settleWin_resettleWin_increased() {
    await this.setTestScenario(
      async () => {
        const options = {
          resettleResult: 'win',
          resettleAmountChange: 'increased',
        }

        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '1' }, // MY [+]
          options: {
            ...options,
            positiveOdds: true,
          },
        })
        await this.requests.adjustBet()

        await this.requests.settleWin({ options })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleWin_resettleWin_decreased() {
    await this.setTestScenario(
      async () => {
        const options = {
          resettleResult: 'win',
          resettleAmountChange: 'decreased',
        }

        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '1' }, // MY [-]
          options,
        })
        await this.requests.adjustBet()

        await this.requests.settleWin({ options })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleWin_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '3' } }) // DEC [+]
        await this.requests.adjustBet()

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleLose_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '4' }, // IND [+]
          options: { positiveOdds: true },
        })
        await this.requests.adjustBet()

        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleTie_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '4' } }) // IND [-]
        await this.requests.adjustBet()

        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleTie_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '5' }, // US [+]
          options: { positiveOdds: true },
        })
        await this.requests.adjustBet()

        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleTie_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '5' } }) // US [-]
        await this.requests.adjustBet()

        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_resettleLose1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsType: '1' }, // MY [-]
        })

        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: { oddsType: '1' }, // MY [+]
        })
        await this.requests.adjustBet({ betIndexes: [1] })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'lose' },
        })

        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_resettleTie1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsType: '3' }, // DEC [+]
        })
        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: {
            odds: 1.1,
            oddsType: '3', // DEC [+]
          },
        })
        await this.requests.adjustBet({ betIndexes: [1] })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'tie' },
        })

        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }
}
