import PlatformActions from './PlatformActions'

// 賠率種類：
// 歐洲盤 decimal [+] >= 1
// 馬來盤 malay [+/-] 下[-]才會發送 adjustBet
// 印尼盤 indo [+/-] >= 1
// 香港盤 hongkong [+]
// parlay在前端下單時畫面就會自動把每張單都轉成decimal odds，所以沒有負賠率

// 1x2(Moneyline) (獨贏盤) 只支持 DEC, 下注 MY [+], HK [+], IND [+] oddsDisplayType 會變成 decimal
// Handicap(HDP) (讓分盤betType=HDP) 及 Total(O/U) (兩隊得分總和讓分盤betType=OU) 支持所有 DEC, MY, HK, IND

// MY [-]
// bet: betAmount = formData.betAmount
// adjustBet: betAmount = formData.betAmount * |odds|
// settle: betAmount = formData.betAmount * |odds|
// MY [+]
// bet: betAmount = formData.betAmount
// settle: betAmount = formData.betAmount

// IND [-]
// bet: betAmount = formData.betAmount * |odds|
// settle: betAmount = formData.betAmount * |odds|
// IND [+]
// bet: betAmount = formData.betAmount
// settle: betAmount = formData.betAmount

// DEC [+], HK [+]
// bet: betAmount = formData.betAmount
// settle: betAmount = formData.betAmount

// 因 HALF_WON_HALF_PUSHED 僅會出現在讓分盤 +-0.25 或 +-0.75，
// 僅有以下 2 個測項的 handicap = -0.25
// "Bet > AdjustBet > Settle Win > Resettle Win (winAmount +)"
// "Bet > AdjustBet > Settle Win > Resettle Win (winAmount -)"
// 其餘情境 handicap = 0 (因讓 分盤 0.0 才會發生 settle tie 退回全部本金的情況)

// HALF_WON_HALF_PUSH：
// 這是指 “一半贏、一半退回”。這種情況通常出現在讓分盤為 0.25球（或 -0.25）或 0.75球（或 -0.75）的情況下

// 解釋：
// 假設你投注了 A隊 -0.25（即A隊讓B隊0.25球），當比賽結束後，假設 A隊以1球贏，那麼你的投注會有以下情況：
// 一半的賭金會視為“贏”（因為A隊贏1球超過了0.5球的讓分），
// 另一半的賭金會被“退回”（因為0.25盤沒有完全超過1球，所以相當於“平局”處理）
// 具體例子：
// 假設你投注 A隊 -0.25 100元（即每單位賭注為100元），比賽結果為 A隊贏1球：

// 你的100元會被分成兩部分：
// 一部分 50元 按 0.5球的盤口計算會贏，會獲得賠款
// 另一部分 50元 會退回（因為0.25盤本來沒有完全達到1球）
// 這樣的情況稱為 "half won, half pushed"，即一部分獲勝、一部分退還

// HALF_LOST_HALF_PUSH：
// 這是指 “一半輸、一半退回”。這種情況同樣出現在 0.25球（或 -0.25）或 0.75球（或 -0.75）這類讓分盤中，但通常是當比賽結果對你的投注不利時。

// 解釋：
// 假設你投注了 A隊 -0.75（即A隊讓B隊0.75球），當比賽結束後，假設 A隊以1球贏，那麼你的投注會有以下情況：
// 一半的賭金會被 退回（因為A隊贏1球，相當於對應0.5球的部分），
// 另一半的賭金會輸掉（因為A隊並未贏超過1球，這部分對應0.75球的部分仍然沒有完全達到）
// 具體例子：
// 假設你投注 A隊 -0.75 100元，比賽結果為 A隊贏1球：

// 你的100元會被分成兩部分：
// 一部分 50元 會被退回（因為A隊贏1球，超過了0.5球的讓分）
// 另一部分 50元 會 輸掉（因為A隊贏1球沒有超過0.75球的讓分）
// 這樣的情況稱為 "half lost, half pushed"，即一部分賭注輸掉、一部分賭注退還

export default class INSPORTSActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SPORTS',
      gameCode: 'INSPORTS-SPORTS-001',
      platform: 'INSPORTS',
    }

    this.gameName = 'INSPORTS Sportsbook'

    this.constantGameInfo = {
      sportId: 1,
      tournamentId: 808,
      tournamentNameI18n: null,
      // marketGroup: 'HDP',
      marketId: 16,
      marketNameI18n: null,
      outcomeId: '1715',
      outcomeNameI18n: null,
      specifiers: {
        hcp: '0',
      },
      homeTeamId: 43561,
      homeTeamNameI18n: null,
      awayTeamId: 249057,
      awayTeamNameI18n: null,
      outrightMarketTextId: null,
      outrightMarketTextNameI18n: null,
      // odds: '0.95',
      // oddsDisplayType: 'malay',
      liveType: 'live',
      liveScore: '0:0', // live比賽下注時的比分，settle.gameInfo的liveScore不會更新成最終比分
      eventId: '54775715',
      // startTime: '2025-02-18T22:00:00+08:00',
    }

    this.oddsDisplayTypeMap = {
      1: 'malay',
      2: 'hongkong',
      3: 'decimal',
      4: 'indo',
    }

    this.getParlayGameInfo = ({ odds, matchDateTime }) => [
      {
        sportId: 1,
        tournamentId: 498,
        tournamentNameI18n: null,
        marketGroup: 'OU',
        marketId: 18,
        marketNameI18n: null,
        outcomeId: '12',
        outcomeNameI18n: null,
        specifiers: {
          total: '1.75',
        },
        homeTeamId: 2510,
        homeTeamNameI18n: null,
        awayTeamId: 402227,
        awayTeamNameI18n: null,
        outrightMarketTextId: null,
        outrightMarketTextNameI18n: null,
        odds: odds[0],
        oddsDisplayType: 'decimal',
        liveType: 'live',
        liveScore: '0:0',
        eventId: '56352721',
        startTime: matchDateTime,
      },
      {
        sportId: 1,
        tournamentId: 27466,
        tournamentNameI18n: null,
        marketGroup: 'HDP',
        marketId: 16,
        marketNameI18n: null,
        outcomeId: '1715',
        outcomeNameI18n: null,
        specifiers: {
          hcp: '0',
        },
        homeTeamId: 1949,
        homeTeamNameI18n: null,
        awayTeamId: 1944,
        awayTeamNameI18n: null,
        outrightMarketTextId: null,
        outrightMarketTextNameI18n: null,
        odds: odds[1],
        oddsDisplayType: 'decimal',
        liveType: 'live',
        liveScore: '0:2',
        eventId: '56591133',
        startTime: matchDateTime,
      },
      {
        sportId: 1,
        tournamentId: 17,
        tournamentNameI18n: null,
        marketGroup: 'OE',
        marketId: 26,
        marketNameI18n: null,
        outcomeId: '70',
        outcomeNameI18n: null,
        specifiers: {},
        homeTeamId: 60,
        homeTeamNameI18n: null,
        awayTeamId: 3,
        awayTeamNameI18n: null,
        outrightMarketTextId: null,
        outrightMarketTextNameI18n: null,
        odds: odds[2],
        oddsDisplayType: 'decimal',
        liveType: 'early',
        liveScore: null,
        eventId: '50850467',
        startTime: matchDateTime,
      },
    ]
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
      oddsType = 2,
      odds,
      isParlay,
      matchDateTime,
      handicap,
    }) => {
      const betType = isParlay ? 'PARLAY' : 'HDP'

      return {
        ...this.constantTxnData,
        gameName: this.gameName,
        userId,
        platformTxId: txnId, // S2025 0218 1415 382 0315 (時區+0 時間)
        roundId: txnId, // S2025021815012820317
        betType,
        currency,
        betTime, // 2025-02-18T22:15:39.191+08:00
        betAmount,
        gameInfo: {
          details: JSON.stringify(isParlay ? this.getParlayGameInfo({ odds, matchDateTime }) : [{
            ...this.constantGameInfo,
            marketGroup: betType,
            ...(handicap && {
              specifiers: {
                hcp: `${handicap}`,
              },
            }),
            odds: `${odds}`,
            oddsDisplayType: this.oddsDisplayTypeMap[oddsType],
            startTime: matchDateTime, // 2025-02-18T22:00:00+08:00
          },
          ]),
          ticketId: txnId,
        },
        isPremium: false,
      }
    })

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getAdjustBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betAmount,
      adjustAmount,
      oddsType = 2,
      odds,
      isParlay,
      matchDateTime,
      handicap,
    }) => {
      const betType = isParlay ? 'PARLAY' : 'HDP'

      return {
        ...this.constantTxnData,
        gameName: this.gameName,
        userId,
        platformTxId: txnId,
        roundId: txnId,
        refPlatformTxId: null,
        settleType: 'platformTxId',
        updateTime,
        betAmount,
        adjustAmount,
        gameInfo: {
          details: JSON.stringify(isParlay ? this.getParlayGameInfo({ odds, matchDateTime }) : [{
            ...this.constantGameInfo,
            marketGroup: betType,
            ...(handicap && {
              specifiers: {
                hcp: `${handicap}`,
              },
            }),
            odds: `${odds}`,
            oddsDisplayType: this.oddsDisplayTypeMap[oddsType],
            startTime: matchDateTime,
          },
          ]),
          ticketId: txnId,
        },
        isPremium: false,
      }
    })

    const message = {
      action: 'adjustBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      betType = 'HDP',
      oddsType = 2,
      odds,
      betAmount,
      updateTime,
      matchDateTime,
      handicap,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId,
      updateTime,
      roundId: txnId,
      betAmount,
      gameInfo: {
        details: JSON.stringify([{
          ...this.constantGameInfo,
          marketGroup: betType,
          ...(handicap && {
            specifiers: {
              hcp: `${handicap}`,
            },
          }),
          odds: `${odds}`,
          oddsDisplayType: this.oddsDisplayTypeMap[oddsType],
          startTime: matchDateTime,
        },
        ]),
        ticketId: txnId,
      },
      voidType: 2,
    }))

    const message = {
      action: 'voidBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      oddsType = 2,
      odds,
      isParlay,
      matchDateTime,
      handicap,
    }) => {
      const betType = isParlay ? 'PARLAY' : 'HDP'

      return {
        ...this.constantTxnData,
        gameName: this.gameName,
        userId,
        platformTxId: txnId,
        refPlatformTxId: null,
        settleType: 'platformTxId',
        updateTime,
        roundId: txnId,
        betType,
        betTime,
        txTime,
        turnover,
        betAmount,
        winAmount,
        gameInfo: {
          details: JSON.stringify(isParlay ? this.getParlayGameInfo({ odds, matchDateTime }) : [{
            ...this.constantGameInfo,
            marketGroup: betType,
            ...(handicap && {
              specifiers: {
                hcp: `${handicap}`,
              },
            }),
            odds: `${odds}`,
            oddsDisplayType: this.oddsDisplayTypeMap[oddsType],
            startTime: matchDateTime,
          },
          ]),
          ticketId: txnId,
        },
        isPremium: false,
      }
    })

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      betType = 'HDP',
      oddsType = 2,
      odds,
      matchDateTime,
      handicap,
      resettleTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      roundId: txnId,
      betType,
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        details: JSON.stringify([{
          ...this.constantGameInfo,
          marketGroup: betType,
          ...(handicap && {
            specifiers: {
              hcp: `${handicap}`,
            },
          }),
          odds: `${odds}`,
          oddsDisplayType: this.oddsDisplayTypeMap[oddsType],
          startTime: matchDateTime,
        },
        ]),
        ticketId: txnId,
        resettleTime,
      },
      isPremium: false,
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      betType = 'HDP',
      oddsType = 2,
      odds,
      matchDateTime,
      handicap,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId: txnId,
      gameInfo: {
        details: JSON.stringify([{
          ...this.constantGameInfo,
          marketGroup: betType,
          ...(handicap && {
            specifiers: {
              hcp: `${handicap}`,
            },
          }),
          odds: `${odds}`,
          oddsDisplayType: this.oddsDisplayTypeMap[oddsType],
          startTime: matchDateTime,
        },
        ]),
        ticketId: txnId,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
