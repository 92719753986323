import SABATestScenarios from './SABATestScenarios'

// oddsType:
// 1 馬來盤 malay [+/-] (下[-]才會發送adjustBet)
// 2 香港盤 hongkong [+]
// 3 歐洲盤 decimal [+] >= 1
// 4 印尼盤 indo [+/-] >= 1

/* eslint-disable camelcase, no-await-in-loop */
export default class INSPORTSTestScenarios extends SABATestScenarios {
  async bet_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({
          data: { oddsType: '4' }, // IND [+]
          options: { positiveOdds: true },
        })
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '4' } })// IND [-]
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '3' } }) // DEC [+]

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.settleWin({ timesLoop })
        }

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustBet_asyncSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '1' } }) // MY [-]
        await this.requests.adjustBet()

        await Promise.all(Array.from({ length: 5 }).map(async (_, timesLoop) => {
          await this.requests.settleWin({
            timesLoop,
            options: { isAsync: true },
          })
          return true
        }))

        await this.requests.getBalance({ action: 'asyncSettle' })
      },
    )
  }

  async bet_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '4' } }) // IND [-]
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_adjustBet_voidBet5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '1' } }) // MY [-]
        await this.requests.adjustBet()

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.voidBet({ timesLoop })
        }
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async betOnParlay_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: {
            oddsType: '3', // DEC [+]
            isParlay: true,
          },
        })

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '4' }, // IND [+]
          options: { positiveOdds: true },
        })

        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleWin_resettleWin_increased() {
    await this.setTestScenario(
      async () => {
        const options = {
          resettleResult: 'win',
          resettleAmountChange: 'increased',
        }

        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '1' }, // MY [+]
          options: {
            ...options,
            positiveOdds: true,
          },
        })

        await this.requests.settleWin({ options })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleWin_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '3' } }) // DEC [+]

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleWin_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '3' } }) // DEC [+]

        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleLose_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '1' }, // MY [+]
          options: { positiveOdds: true },
        })

        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_adjustBet_settleLose_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({ data: { oddsType: '1' } })// MY [-]
        await this.requests.adjustBet()

        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleLose_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          data: { oddsType: '4' }, // IND [+]
          options: { positiveOdds: true },
        })

        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleTie_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '4' } }) // IND [-]

        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleTie_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '3' } })// DEC [+]

        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleLose_resettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ data: { oddsType: '4' } }) // IND [-]

        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.resettle({
            options: { resettleResult: 'win' },
            timesLoop,
          })
        }

        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async negativeBalance_bet1_adjustBet1_settleWin1_bet2_resettleLose1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsType: '1' }, // MY [-]
        })

        await this.requests.adjustBet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: { oddsType: '1' }, // MY [+]
        })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'lose' },
        })

        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_resettleTie1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.bet({
          betIndexes: [0],
          data: { oddsType: '3' }, // DEC [+]
        })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          data: {
            odds: 1.1,
            oddsType: '3', // DEC [+]
          },
        })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'tie' },
        })

        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }
}
