export default [
  {
    name: 'Bet > AdjustBet > Settle Win (oddsDisplayType = malay ‒)',
    functionName: 'bet_adjustBet_settleWin',
  },
  {
    name: 'Bet > Settle Lose (oddsDisplayType = indo +)',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Tie (oddsDisplayType = indo -)',
    functionName: 'bet_settleTie',
  },
  {
    name: 'Bet > Settle Win 5 times (oddsDisplayType = decimal +)',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > AdjustBet > (Network) Settle Win 5 times (oddsDisplayType = malay ‒)',
    functionName: 'bet_adjustBet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > VoidBet (oddsDisplayType = indo -)',
    functionName: 'bet_voidBet',
  },
  {
    name: 'Bet > AdjustBet > VoidBet 5 times (oddsDisplayType = malay ‒)',
    functionName: 'bet_adjustBet_voidBet5Times',
  },
  {
    name: 'Bet > CancelBet (oddsDisplayType = hongkong +)',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'CancelBet only (oddsDisplayType = hongkong +)',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet (oddsDisplayType = hongkong +)',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet (oddsDisplayType = hongkong +)',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Bet > CancelBet 5 times (oddsDisplayType = hongkong +)',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'Bet on Parlay > Settle Win (oddsDisplayType = decimal +)',
    functionName: 'betOnParlay_settleWin',
  },
  {
    name: 'Bet > Settle Win > Resettle Win (oddsDisplayType = indo +)',
    functionName: 'bet_settleWin_resettleWin',
  },
  {
    name: 'Bet > Settle Win > Resettle Win (winAmount +) (oddsDisplayType = malay +)',
    functionName: 'bet_settleWin_resettleWin_increased',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Win (winAmount -) (oddsDisplayType = malay ‒)',
    functionName: 'bet_adjustBet_settleWin_resettleWin_decreased',
  },
  {
    name: 'Bet > Settle Win > Resettle Lose (oddsDisplayType = decimal +)',
    functionName: 'bet_settleWin_resettleLose',
  },
  {
    name: 'Bet > Settle Win > Resettle Tie (oddsDisplayType = decimal +)',
    functionName: 'bet_settleWin_resettleTie',
  },
  {
    name: 'Bet > Settle Lose > Resettle Win (oddsDisplayType = malay +)',
    functionName: 'bet_settleLose_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Lose (oddsDisplayType = malay -)',
    functionName: 'bet_adjustBet_settleLose_resettleLose',
  },
  {
    name: 'Bet > Settle Lose > Resettle Tie (oddsDisplayType = indo +)',
    functionName: 'bet_settleLose_resettleTie',
  },
  {
    name: 'Bet > Settle Tie > Resettle Win (oddsDisplayType = indo ‒)',
    functionName: 'bet_settleTie_resettleWin',
  },
  {
    name: 'Bet > Settle Tie > Resettle Lose (oddsDisplayType = decimal +)',
    functionName: 'bet_settleTie_resettleLose',
  },
  {
    name: 'Bet > Settle Tie > Resettle Tie (oddsDisplayType = hongkong +)',
    functionName: 'bet_settleTie_resettleTie',
  },
  {
    name: 'Bet > Settle Lose > Resettle Win 5 times (oddsDisplayType = indo -)',
    functionName: 'bet_settleLose_resettleWin5Times',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Lose (oddsDisplayType = malay ‒)',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_resettleLose1_settleTie2',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Tie (oddsDisplayType = decimal +)',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_resettleTie1_settleTie2',
  },
  {
    name: 'Positive Balance - Bet (oddsDisplayType = hongkong +)',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet (oddsDisplayType = hongkong +)',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet (oddsDisplayType = hongkong +)',
    functionName: 'negativeBalance_bet',
  },
]
