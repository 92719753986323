import PlatformTestScenarios from './PlatformTestScenarios'
// import PlatformAdjustBetScenarios from './PlatformAdjustBetTestScenarios'
import PlatformBetNSettleTestScenarios from './PlatformBetNSettleTestScenarios'

import SEXYBCRTTestScenarios from './SEXYBCRTTestScenarios'
import SV388TestScenarios from './SV388TestScenarios'
import E1SPORTTestScenarios from './E1SPORTTestScenarios'
import HORSEBOOKTestScenarios from './HORSEBOOKTestScenarios'
import KINGMAKERSLOTTestScenarios from './KINGMAKERSLOTTestScenarios'
import KINGMAKERTABLETestScenarios from './KINGMAKERTABLETestScenarios'
import SPADESLOTTestScenarios from './SPADESLOTTestScenarios'
import AELOTTOTestScenarios from './AELOTTOTestScenarios'
import BGTestScenarios from './BGTestScenarios'
import SABAOLDTestScenarios from './SABAOLDTestScenarios'
import SABATestScenarios from './SABATestScenarios'
import AELIVECOMMTestScenarios from './AELIVECOMMTestScenarios'
import PGSLOTMIXEDTestScenarios from './PGSLOTMIXEDTestScenarios'
import VRLOTTOTestScenarios from './VRLOTTOTestScenarios'
import JOKERTestScenarios from './JOKERTestScenarios'
import INSPORTSTestScenarios from './INSPORTSTestScenarios'

export default {
  SEXYBCRT: SEXYBCRTTestScenarios,
  SEXYBCRT_ONEDAY: SEXYBCRTTestScenarios,
  SEXYBCRT_SUMMARY: SEXYBCRTTestScenarios,
  SV388: SV388TestScenarios,
  E1SPORT: E1SPORTTestScenarios,
  HORSEBOOK: HORSEBOOKTestScenarios,
  YLEGAME: PlatformTestScenarios,
  YLFISH: PlatformBetNSettleTestScenarios,
  PGSLOT: PlatformBetNSettleTestScenarios,
  PGSLOTOLD: PlatformTestScenarios,
  PGSLOTMIXED: PGSLOTMIXEDTestScenarios,
  PGTABLE: PlatformBetNSettleTestScenarios,
  VENUS: SEXYBCRTTestScenarios,
  KINGMAKERSLOT: KINGMAKERSLOTTestScenarios,
  KINGMAKERTABLE: KINGMAKERTABLETestScenarios,
  KINGMAKERMINI: KINGMAKERTABLETestScenarios,
  JILIFISH: PlatformBetNSettleTestScenarios,
  JILISLOT: PlatformBetNSettleTestScenarios,
  JILITABLE: PlatformTestScenarios,
  JDBEGAME: PlatformBetNSettleTestScenarios,
  JDBFISH: PlatformBetNSettleTestScenarios,
  JDBSLOT: PlatformBetNSettleTestScenarios,
  JDBTABLE: PlatformTestScenarios,
  GTFFISH: PlatformBetNSettleTestScenarios,
  GTFSLOT: PlatformBetNSettleTestScenarios,
  AWSEGAME: PlatformBetNSettleTestScenarios,
  AWSSLOT: PlatformBetNSettleTestScenarios,
  AWSTABLE: PlatformBetNSettleTestScenarios,
  SPADEEGAME: PlatformTestScenarios,
  SPADEFISH: PlatformBetNSettleTestScenarios,
  SPADESLOT: SPADESLOTTestScenarios,
  PPEGAME: PlatformTestScenarios,
  PPLIVE: PlatformTestScenarios,
  PPSLOT: PlatformTestScenarios,
  AELOTTO: AELOTTOTestScenarios,
  BG: BGTestScenarios,
  FCEGAME: PlatformBetNSettleTestScenarios,
  FCFISH: PlatformBetNSettleTestScenarios,
  FCSLOT: PlatformBetNSettleTestScenarios,
  SABA: SABATestScenarios,
  SABAOLD: SABAOLDTestScenarios,
  RTSLOT: PlatformTestScenarios,
  RTTABLE: PlatformTestScenarios,
  LADYLUCK: PlatformTestScenarios,
  LUDO: PlatformTestScenarios,
  AELIVECOMM: AELIVECOMMTestScenarios,
  LUCKYPOKER: PlatformTestScenarios,
  FASTSPINSLOT: PlatformTestScenarios,
  FASTSPINFISH: PlatformBetNSettleTestScenarios,
  PLAY8FISH: PlatformBetNSettleTestScenarios,
  PLAY8SLOT: PlatformBetNSettleTestScenarios,
  YESBINGOBINGO: PlatformTestScenarios,
  YESBINGOEGAME: PlatformTestScenarios,
  YESBINGOFISH: PlatformBetNSettleTestScenarios,
  YESBINGOSLOT: PlatformTestScenarios,
  PTLIVE: PlatformTestScenarios,
  PTSLOT: PlatformTestScenarios,
  VRLOTTO: VRLOTTOTestScenarios,
  DRAGOONSOFT: PlatformTestScenarios,
  EVOLUTION: PlatformTestScenarios,
  BTG: PlatformTestScenarios,
  NETENTSLOT: PlatformTestScenarios,
  NETENTTABLE: PlatformTestScenarios,
  NLC: PlatformTestScenarios,
  HOTROAD: PlatformTestScenarios,
  ILOVEU: PlatformBetNSettleTestScenarios,
  SPRIBE: PlatformTestScenarios,
  VIACASINO: PlatformTestScenarios,
  JOKER: JOKERTestScenarios,
  SKYCASINO: SEXYBCRTTestScenarios,
  PINNACLE: SABATestScenarios,
  CGEGAME: PlatformTestScenarios,
  CGSLOT: PlatformTestScenarios,
  INSPORTS: INSPORTSTestScenarios,
  FIVEG: PlatformTestScenarios,
}
